import { dev } from '$app/environment';
import { PUBLIC_ENV } from '$env/static/public';
import * as Sentry from '@sentry/sveltekit';
import { browserProfilingIntegration, replayIntegration } from '@sentry/sveltekit';
import type { HandleClientError } from '@sveltejs/kit';

Sentry.init({
	dsn: 'https://98c2147f2b6d5bc73062e563364a2d74@o4507658177085440.ingest.us.sentry.io/4507658182393856',
	tunnel: '/api/tunnel',
	tracesSampleRate: 1,

	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1,

	integrations: [
		browserProfilingIntegration(),
		replayIntegration({
			maskAllText: true,
			maskAllInputs: true,
			blockAllMedia: false,
			useCompression: false,
		}),
	],

	profilesSampleRate: 1,

	environment: dev ? 'development' : (PUBLIC_ENV ?? 'production'),
	enabled: !dev,
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError: HandleClientError = async ({ error, event, status, message }) => {
	const errorId = crypto.randomUUID();

	// example integration with https://sentry.io/
	Sentry.captureException(error, {
		extra: { event, errorId, status },
	});

	if (dev) {
		console.error(error);
	}

	return {
		message: 'Whoops!',
		errorId,
	};
};
